<template>
<div :style="elementStyle"  class="d-flex"
     style="position: relative;">
  <div style="width : 100%"
       :id="`email_element_${element.uuid}`"
       class="d-flex"
       :class="[
            {'justify-center' : !['text','heading'].includes(element.type) && element.config.align === 'center'},
            {'justify-end'    : !['text','heading'].includes(element.type) && element.config.align === 'right'}
         ]"
  >


    <email-text-element-viewer
        v-if="['heading' , 'text'].includes(element.type)"
        style="width: 100%"
        :element="element"
        :key="element.uuid + index"
        preview
        :config="config"
    />
    <email-image-element-editor
        v-else-if="element.type === 'image'"
        :element="element"
        :key="element.uuid + index"
        preview
        :config="config"
    />

    <email-button-element-viewer
        v-else-if="element.type === 'button'"
        :element="element"
        :key="element.uuid + index"
        :preview="preview"
        :config="config"
    />
    <email-line-element-editor
        v-else-if="element.type === 'line'"
        :element="element"
        :key="element.uuid + index"
        :config="config"
    />

    <email-file-element-editor
        v-else-if="element.type === 'file'"
        :element="element"
        :key="element.uuid + index"
        :config="config"
    />

    <div v-else>
       {{ element.type }}
    </div>

  </div>


</div>
</template>

<script>
import EmailTextElementViewer
  from "@/modules/finemailer/components/emailConstructor/viewer/elements/emailTextElementViewer.vue";
import EmailButtonElementViewer
  from "@/modules/finemailer/components/emailConstructor/viewer/elements/emailButtonElementViewer.vue";
import emailLineElementEditor
  from "@/modules/finemailer/components/emailConstructor/editor/elements/emailLineElementEditor.vue";
import emailImageElementEditor
  from "@/modules/finemailer/components/emailConstructor/editor/elements/emailImageElementEditor.vue";
import emailFileElementEditor
  from "@/modules/finemailer/components/emailConstructor/editor/elements/emailFileElementEditor.vue";
import finemailer from "@modules/finemailer/mixins/finemailer";

export default {
  name: "emailElementViewer",
  mixins : [finemailer],
  components: {
    emailFileElementEditor,
    emailImageElementEditor, emailLineElementEditor, EmailButtonElementViewer, EmailTextElementViewer },
  props : {
    element : {
      type : Object,
      default() {
        return {
          config : {}
        }
      }
    },
    index : {
      type : Number
    },
    preview : {
      type : Boolean,
      default : false
    }
  },
  computed : {
    elementStyle() {
      let style = ''

      if ( this.element.config.margin_top) {
        style += `margin-top : ${this.element.config.margin_top}px;`
      }

      if ( this.element.config.margin_bottom) {
        style += `margin-bottom : ${this.element.config.margin_bottom}px;`
      }

      if ( this.displayHover) {
        style += `box-shadow: inset 0px 0px 0 2px ${this.wsATTENTION};`
        ;
      }

      return style
    }
  }
}
</script>



<style scoped>

</style>